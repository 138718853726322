/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bag': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.25 2.75c0-.543.619-1.25 1.75-1.25s1.75.707 1.75 1.25h1.5C11.25 1.084 9.63 0 8 0S4.75 1.084 4.75 2.75h1.5zM0 3.75a1 1 0 011-1h14a1 1 0 011 1V7h-4.25v-.25a.75.75 0 00-1.5 0V7h-4.5v-.25a.75.75 0 00-1.5 0V7H0V3.75zM11.75 8.5H16v4.25a1 1 0 01-1 1H1a1 1 0 01-1-1V8.5h4.25v.25a.75.75 0 001.5 0V8.5h4.5v.25a.75.75 0 001.5 0V8.5z" _fill="#fff"/>'
  }
})
